.Landing {
  margin: 0 0;
  margin-bottom: 20%;
}
.splash {
  display: flex;
  flex-flow: column;
  height: 70vh;
  justify-content: center;
  align-items: center;
  background: transparent url("../../assets/images/SiteCauldronLogo1v2.png") 50%
    15% no-repeat;
}
.splash a {
  text-decoration: none;
  color: #ffffff;
  font-size: larger;
}
.splash header {
  display: flex;
  flex-flow: row;
  justify-content: center;
  font-size: 6rem;
  font-weight: bold;
  font-family: "Times New Roman", Times, serif;
  color: #2c0a29;
}
.Body {
  padding-left: 10px;
  padding-right: 10px;
}

.Landing p {
  display: flex;
  padding: 10px;
}

.link {
  display: flex;
  width: fit-content;
  margin: 10px 0;
  padding: 1.2em 0.4em;
  font-weight: bold;
  text-decoration: none;
  background: #218a50;
  color: #ffffff;
  border-radius: 0.2em;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.096);
}
