@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
.ImageBox_ImageBox__1IJam{
    background-color: #805550;
    width:30%;
    border:3px solid aliceblue;
    border-radius: 2px;
}
.Landing_Landing__3TXwh {
  margin: 0 0;
  margin-bottom: 20%;
}
.Landing_splash__16V1D {
  display: flex;
  flex-flow: column;
  height: 70vh;
  justify-content: center;
  align-items: center;
  background: transparent url(/static/media/SiteCauldronLogo1v2.69f88671.png) 50%
    15% no-repeat;
}
.Landing_splash__16V1D a {
  text-decoration: none;
  color: #ffffff;
  font-size: larger;
}
.Landing_splash__16V1D header {
  display: flex;
  flex-flow: row;
  justify-content: center;
  font-size: 6rem;
  font-weight: bold;
  font-family: "Times New Roman", Times, serif;
  color: #2c0a29;
}
.Landing_Body__1Cruv {
  padding-left: 10px;
  padding-right: 10px;
}

.Landing_Landing__3TXwh p {
  display: flex;
  padding: 10px;
}

.Landing_link__1PFjH {
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 10px 0;
  padding: 1.2em 0.4em;
  font-weight: bold;
  text-decoration: none;
  background: #218a50;
  color: #ffffff;
  border-radius: 0.2em;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.096);
}

.Logo_Logo__1cDQN {
  padding: 0.4em;
  height: 100%;
  box-sizing: border-box;
  border-radius: 5px;
}
.Logo_Logo__1cDQN img {
  height: 100%;
}

@media (max-width: 700px) {
  .Logo_Logo__1cDQN img {
    width: 90%;
  }
}

.DrawerToggle_DrawerToggle__Grf3E {
  width: 40px;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  box-sizing: border-box;
  cursor: pointer;
}

.DrawerToggle_DrawerToggle__Grf3E div {
  border-radius: 22%;
  width: 90%;
  height: 3px;
  background-color: #eeeeee;
}

@media (min-width: 700px) {
  .DrawerToggle_DrawerToggle__Grf3E {
    display: none;
  }
}

.NavItem_NavigationItem__3q3Nk {
  margin: 0.75em 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
}

.NavItem_NavigationItem__3q3Nk a {
  color: #eeeeee;
  text-decoration: none;
  width: 100%;
}

.NavItem_NavigationItem__3q3Nk a:hover,
.NavItem_NavigationItem__3q3Nk a:active,
.NavItem_NavigationItem__3q3Nk a.NavItem_active__3hREs {
  color: #368f22;
}
.NavItem_NavigationItem__3q3Nk img {
  height: 2.3rem;
  font-size: 95%;
}
.NavItem_nonAuth__1Wrri {
  display: none;
}
@media (min-width: 700px) {
  .NavItem_NavigationItem__3q3Nk {
    margin: 0 10px;
    padding: 20px 2px;
    width: auto;
  }

  .NavItem_NavigationItem__3q3Nk a {
    color: white;
    height: 100%;
  }

  .NavItem_NavigationItem__3q3Nk:hover {
    color: white;
    background-color: #391b4f;
  }
  .NavItem_NavigationItem__3q3Nk a:hover,
  .NavItem_NavigationItem__3q3Nk a:active,
  .NavItem_NavigationItem__3q3Nk a.NavItem_active__3hREs {
    padding-bottom: 20px;
    border-bottom: 4px solid #68318f;
    color: white;
  }
  .NavItem_nonAuth__1Wrri {
    display: none;
  }
}

.NavigationFlow_NavigationItems__1ADIc {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: column;
  height: 100%;
}

@media (min-width: 700px) {
  .NavigationFlow_NavigationItems__1ADIc {
    flex-flow: row;
  }
}

.Navbar_Navbar__1b0gN {
  background-color: #000000b2;
  height: 4em;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
  box-sizing: border-box;
  z-index: 90;

  border-bottom: 4px solid #368f22;
}

.Navbar_Navbar__1b0gN nav {
  height: 100%;
}

.Navbar_Logo__doA_R {
  height: 110%;
}

@media (max-width: 699px) {
  .Navbar_DesktopOnly__2d1fn {
    display: none;
  }
}

.Footer_Footer__2w8_1 {
  position: fixed;
  display: flex;
  align-items: center;
  left: 0;
  bottom: 0;
  font-family: "Lustria", serif;
  width: 100%;
  padding-bottom: 10px;
  background-color: #4b1e66;
}
.Footer_Footer__2w8_1 div {
  color: #8e40bd;
  text-align: center;
  display: grid;
  width: 33%;
}
.Footer_Footer__2w8_1 h2 {
  color: #0ace59;
  font-weight: 200;
  margin-bottom: 5px;
}
.Footer_Footer__2w8_1 p {
  margin: 0;
}

.Backdrop_Backdrop__1gj8E {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
}
.SideDrawer_SideDrawer__22qJy {
  position: fixed;
  max-width: 66%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 200;
  background-color: #000000b2;
  padding: 2em 1em;
  box-sizing: border-box;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

@media (min-width: 700px) {
  .SideDrawer_SideDrawer__22qJy {
    display: none;
  }
}

.SideDrawer_Open__1N6m1 {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.SideDrawer_Close__EbhhJ {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.Layout_Content__1wFKq {
  margin: 64px 0;
}

.Layout_Logo__KMrCH {
  height: 11%;
  margin-bottom: 32px;
}

.Spinner_spinner__KKysY {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.Spinner_cube1__3y_LP,
.Spinner_cube2__2ZOlB,
.Spinner_cube3__LVZqw {
  width: 1.2em;
  height: 1.2em;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: Spinner_sk-cubemove__3zYLl 4s infinite ease-in-out;
  animation: Spinner_sk-cubemove__3zYLl 4s infinite ease-in-out;
}
.Spinner_cube1__3y_LP {
  background-color: rgba(75, 0, 62, 0.733);
}
.Spinner_cube2__2ZOlB {
  background-color: rgba(210, 60, 255, 0.733);
}
.Spinner_cube3__LVZqw {
  background-color: rgba(20, 202, 20, 0.733);
}

.Spinner_cube2__2ZOlB {
  -webkit-animation-delay: -1.5s;
  animation-delay: -1.5s;
}

.Spinner_cube3__LVZqw {
  -webkit-animation-delay: -2.9s;
  animation-delay: -2.9s;
}

@-webkit-keyframes Spinner_sk-cubemove__3zYLl {
  25% {
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  50% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg)
      scale(0.5);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes Spinner_sk-cubemove__3zYLl {
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  50% {
    transform: translateX(42px) translateY(42px) rotate(-179deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
  }
  50.1% {
    transform: translateX(42px) translateY(42px) rotate(-180deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg)
      scale(0.5);
  }
  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}

body {
  background: #d9dad9;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #8e40bd;
  background-image: url("https://www.transparenttextures.com/patterns/strange-bullseyes.png");
}

