.Navbar {
  background-color: #000000b2;
  height: 4em;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
  box-sizing: border-box;
  z-index: 90;

  border-bottom: 4px solid #368f22;
}

.Navbar nav {
  height: 100%;
}

.Logo {
  height: 110%;
}

@media (max-width: 699px) {
  .DesktopOnly {
    display: none;
  }
}
