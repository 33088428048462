.NavigationItem {
  margin: 0.75em 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
}

.NavigationItem a {
  color: #eeeeee;
  text-decoration: none;
  width: 100%;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
  color: #368f22;
}
.NavigationItem img {
  height: 2.3rem;
  font-size: 95%;
}
.nonAuth {
  display: none;
}
@media (min-width: 700px) {
  .NavigationItem {
    margin: 0 10px;
    padding: 20px 2px;
    width: auto;
  }

  .NavigationItem a {
    color: white;
    height: 100%;
  }

  .NavigationItem:hover {
    color: white;
    background-color: #391b4f;
  }
  .NavigationItem a:hover,
  .NavigationItem a:active,
  .NavigationItem a.active {
    padding-bottom: 20px;
    border-bottom: 4px solid #68318f;
    color: white;
  }
  .nonAuth {
    display: none;
  }
}
