@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

body {
  background: #d9dad9;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #8e40bd;
  background-image: url("https://www.transparenttextures.com/patterns/strange-bullseyes.png");
}
